.main__header-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 60vh;
}
.main__header-container--inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.6rem;
	margin-bottom: 6rem;
}
.highlighted-intro {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 2rem;
	color: #08d9d6;
	margin-bottom: 1.2rem;
}

.name {
	font-size: 5rem;
}
.title {
	font-size: 5rem;
	color: rgba(255, 255, 255, 0.8);
	margin-bottom: 3rem;
}
.text {
	max-width: 80rem;
	text-align: center;
	font-family: "Open Sans", sans-serif;
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 1.8;
	color: rgba(255, 255, 255, 0.65);
}

@media only screen and (max-width: 960px) {
	html {
		font-size: 50%;
	}
	.highlighted-intro {
		margin-bottom: 0;
	}
	.title {
		margin-bottom: 2rem;
		text-align: center;
	}
	.main__header-container--inner {
		margin-bottom: 4rem;
	}
	.text {
		font-size: 1.6rem;
	}
}
@media only screen and (max-width: 760px) {
	.name {
		font-size: 4rem;
	}
	.title {
		font-size: 4rem;
	}
	.main__header-container--inner {
		font-size: 4rem;
	}
	.text {
		font-size: 1.4rem;
	}
}
@media only screen and (max-width: 630px) {
	.name {
		font-size: 3.2rem;
	}
	.title {
		font-size: 3.2rem;
	}
	.main__header-container--inner {
		font-size: 3.2rem;
	}
	.text {
		font-size: 1.4rem;
	}
}
