.footer {
	display: flex;
	justify-content: center;
	/* text-align: center; */
	padding: 4rem 0 4rem 0;
	/* height: 20rem; */
	background-color: #3b3f48;
}
.footer-text {
	display: inline-block;
	color: rgba(255, 255, 255, 0.65);
	font-family: "Montserrat", sans-serif;
	font-size: 1.2rem;
	font-weight: 500;
}
.footer-link {
	text-decoration: none;
}
.footer-link span {
	color: rgba(8, 217, 214, 0.65);
	transition: 0.4s ease-out;
}
.footer-link:hover span {
	color: #fff;
}
.footer-link:hover .footer-text {
	color: #08d9d6;
}

@media only screen and (max-width: 780px) {
	.footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		gap: 4rem;
	}
}
