.sideSocial {
	width: 40px;
	position: fixed;
	bottom: 0px;
	left: 40px;
	right: auto;
	z-index: 10;
	color: rgba(255, 255, 255, 0.8);
}

.main__social-container {
	display: flex;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	gap: 1.4rem;
	margin: 0;
	padding: 0;
	list-style: none;
}

.main__social-container::after {
	content: "";
	display: block;
	width: 1px;
	height: 9rem;
	margin: 0 auto;
	background-color: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width: 780px) {
	.sideSocial {
		width: auto;
		height: auto;
		position: initial;
		color: rgba(255, 255, 255, 0.8);
	}
	.main__social-container {
		flex-direction: row;
	}
	.main__social-container::after {
		display: none;
	}
	.main__social-container svg {
		width: 20px;
		height: 20px;
	}
}
