*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}
body {
	overflow-x: hidden;
	font-family: "Montserrat", sans-serif;
	background-color: #252a34;
	color: #f1f5f9;
	font-size: inherit;
}

/* Scroll Bar */

*::-webkit-scrollbar {
	width: 8px;
}
*::-webkit-scrollbar-thumb {
	background-color: rgba(241, 245, 249, 0.8);
	border-radius: 1rem;
}
*::-webkit-scrollbar-thumb {
	background: #f1f5f9;
}

#root {
	margin: 0 auto;
}

a:hover .upwork path {
	fill: #08d9d6;
}

.modalOpened {
	overflow-y: hidden;
}
.hidOverflowY {
	position: relative;
	overflow-y: hidden;
	height: 100vh;
}
