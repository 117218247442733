.aboutTech {
	min-width: 40%;
}

.aboutTech-h3 {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 2.6rem;
	color: #fff;
	margin-bottom: 1rem;
}

.aboutTech-p {
	font-family: "Open Sans", sans-serif;
	font-size: 1.6rem;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.8);
	margin-bottom: 1.5rem;
}

.aboutTech-technologies {
	display: flex;
	gap: 1.5rem;
	flex-wrap: wrap;
	margin-bottom: 3rem;
}
.aboutTech-referrals {
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	margin-bottom: 2rem;
}
@media only screen and (max-width: 1030px) {
	.aboutTech {
	}
	.aboutTech-technologies {
	}
	.aboutTech-referrals {
	}
}
@media only screen and (max-width: 600px) {
	.aboutTech-technologies {
		justify-content: center;
	}
	.aboutTech-referrals {
		justify-content: center;
	}
}
