.aboutMain {
	display: flex;
	/* flex-wrap: wrap; */
	gap: 12rem;
	max-width: 1400px;
	margin: 0 auto;
	opacity: 0;
}

@media only screen and (max-width: 1300px) {
	.aboutMain {
		gap: 6rem;
	}
}
@media only screen and (max-width: 1030px) {
	.aboutMain {
		flex-wrap: wrap;
	}
}
@media only screen and (max-width: 600px) {
	.aboutMain {
		text-align: center;
	}
}
