.btn {
	display: inline-block;
	text-decoration: none;
	padding: 2rem 4rem;
	font-size: 1.5rem;
	font-weight: 600;
	position: relative;
	color: inherit;
	font-family: "Montserrat", sans-serif;
	cursor: pointer;
}
.btnCenter {
	align-self: center;
}
.btn::after,
.btn::before {
	content: "";
	position: absolute;
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	left: 0;
	bottom: 0;
	transform: translate(0%, -4%);
	z-index: 1;
	transition: transform 0.3s ease;
}
.btn1ColorRed::after {
	border: 3px solid #ff2e63;
}

.btn1ColorBlue::before {
	border: 3px solid #08d9d6;
}

.btn2ColorRed::before {
	border: 3px solid #ff2e63;
}
.btn2ColorBlue::after {
	border: 3px solid #08d9d6;
}
.btn:hover::after {
	transform: translate(-5px, -5px);
}

.btn:hover::before {
	transform: translate(5px, 5px);
}
