.about {
	background-color: #3b3f48;
}

@media only screen and (max-width: 1460px) {
	.about {
		padding: 8rem 8rem 8rem;
	}
}
@media only screen and (max-width: 1110px) {
	.about {
		padding: 8rem 10rem 8rem;
	}
}
