.btn {
	opacity: 0;
	animation: show 0.6s ease 6s forwards;
}
.notFound {
	height: 100vh;
	display: grid;
	place-items: center;
	position: relative;
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	font-size: 24rem;
	/* transform: rotate(-5deg); */
	overflow: hidden;
}
.container {
	display: flex;
	height: 0px;
}

.deathStar {
	position: relative;
}
.deathStar svg {
	width: 30rem;
}

.deathStar path {
	animation: damage 1s ease forwards 5s;
}
.blast1,
.blast2 {
	position: absolute;
	bottom: 6%;
	transform: rotate(90deg);
	width: 0;
	animation: blaster 2s ease forwards 3.4s;
}
.blast1 {
	left: 46.5%;
}
.blast2 {
	right: 47.5%;
}
.xwing {
	position: absolute;
	bottom: -150%;
	left: 50%;
	transform: translateX(-60%);
	width: 8rem;
	animation: xwing 6s ease forwards;
}

@keyframes xwing {
	0% {
		transform: rotate(0);
		left: 50%;
		transform: translateX(-60%);
	}
	65% {
		transform: rotate(-70deg);
		left: 50%;
		transform: translateX(-60%);
	}
	90% {
		left: 30%;
		transform: rotate(-70deg);
	}
	100% {
		bottom: 25%;
		left: -150%;
		transform: rotate(-70deg);
	}
}

@keyframes blaster {
	0% {
		width: 4rem;
	}
	90% {
		bottom: 45%;
		opacity: 1;
		width: 4rem;
	}
	100% {
		display: none;
		opacity: 0;
		bottom: 45%;
		width: 4rem;
	}
}

@keyframes damage {
	10% {
		transform: rotate(-2deg);
		fill: red;
		color: red;
	}
	30% {
		transform: rotate(2deg);
		fill: white;
		color: white;
	}
	50% {
		transform: rotate(-2deg);
		fill: red;
		color: red;
	}
	70% {
		transform: rotate(2deg);
		fill: white;
		color: white;
	}
}
@keyframes show {
	to {
		opacity: 1;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		transform: scale(0.7) translateY(150px);
		width: 0px;
		justify-content: center;
	}
	.xwing {
		display: none;
	}
	.blast1,
	.blast2 {
		display: none;
	}
	.deathStar path {
		animation: none;
	}
	a {
		opacity: 1;
		animation: none;
	}
}
@media only screen and (max-width: 370px) {
	.container {
		transform: scale(0.5) translateY(250px);
		width: 0px;
		justify-content: center;
	}
}
