.loader__container {
	height: 100%;
	width: 100%;
	display: grid;
	place-items: center;
	position: fixed;
	top: 0px;
	right: 0px;
	animation: fadein 3.6s linear forwards;
}
.loader__svg {
	animation: dash 3s linear forwards;
}
.path {
	animation: dash 2.5s linear forwards 0.3s;
}
@keyframes dash {
	0% {
		stroke-dasharray: 600;
		stroke-dashoffset: 600;
		width: 12rem;
		height: 12rem;
	}
	15% {
		stroke-dasharray: 600;
		stroke-dashoffset: 600;
		width: 12rem;
		height: 12rem;
	}
	80% {
		stroke-dashoffset: 0;
		width: 12rem;
		height: 12rem;
	}
	98% {
		width: calc(40rem + 1vw);
		height: calc(40rem + 1vw);
	}
	100% {
		stroke-dasharray: 600;
		stroke-dashoffset: 600;
	}
}

@keyframes fadein {
	0% {
		z-index: 1000;
		background-color: #252a34;
	}
	78% {
		z-index: 1000;
		background-color: #252a34;
	}
	100% {
		z-index: -1;
		background-color: transparent;
	}
}
@keyframes dash2 {
	0% {
		stroke-dasharray: 600;
		stroke-dashoffset: 600;
	}
	15% {
		stroke-dasharray: 600;
		stroke-dashoffset: 600;
	}
	80% {
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dasharray: 600;
		stroke-dashoffset: 600;
	}
}
@media only screen and (max-width: 600px) {
	.loader__svg {
		animation: dash2 3s linear forwards;
		width: 20rem;
		height: 20rem;
	}
}
