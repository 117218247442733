.navlink-btn,
.navlink-btn::after {
	font-size: 1.6rem;
	transition: 0.3s ease;
}
.navlink-btn:visited,
.navlink-btn:link {
	text-decoration: none;
	color: #eaeaea;
}
.navlink-btn::after {
	content: "";
	display: block;
	margin-top: 4px;
	width: 0%;
	height: 2px;
	background-color: #08d9d6;
}
.navlink-btn:hover {
	color: #fff;
}
.navlink-btn:hover::after {
	width: 100%;
}

.navlink-btn:active::after {
	background-color: #ff2e63;
}

@media only screen and (max-width: 760px) {
	.navlink-btn {
		font-size: 4rem;
		font-weight: 600;
	}
}
