.modalContainer {
	display: grid;
	place-items: center;
	height: 100%;
	animation: fadeGrow 0.3s ease;
}

.modalContent {
	background-color: #fff;
	max-width: 80rem;
	border-radius: 12px;
	overflow: hidden;
	margin: 4rem;
}
.modalHead {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 1rem;
	height: 40%;
	width: 100%;
	background-color: #3b3f48;
	padding: 2rem 0;
}
.modalHeadImg {
	width: 10rem;
	height: 10rem;
	border-radius: 100%;
}
.modalMainContent {
	color: #1a1a1a;
	text-align: center;
	padding: 2rem 0 3rem 0;
}
.modalHeadContent h2 {
	font-size: 2.4rem;
}
.modalHeadContent p {
	font-size: 1.4rem;
}
.modalMainContent h4 {
	font-family: "Montserrat", sans-serif;
	font-size: 2.4rem;
	position: relative;
	margin: 1rem 0 2rem 0;
}
.modalMainContent h4::after {
	content: "";
	position: absolute;
	left: 50%;
	bottom: -40%;
	width: 3rem;
	height: 0.6rem;
	border-radius: 40px;
	transform: translateX(-50%);
	background-color: #ff2e63;
}
.modalMainContent p {
	font-family: "Open Sans", sans-serif;
	padding: 0 4rem;
	font-size: 1.4rem;
	line-height: 1.5;
	padding-top: 1rem;
}
.modalSocial {
	display: flex;
	justify-content: center;
	gap: 1rem;
	align-items: center;
	margin-top: 4rem;
}
.modalSocial svg path {
	stroke: #ff2e63;
}
.modalSocial svg,
.modalSocial svg path {
	transition: 0.3s ease;
}
.modalSocial svg:hover {
	transform: translateY(-5px);
}
.modalSocial svg:hover path {
	stroke: #08d9d6;
}

@keyframes fadeGrow {
	0% {
		opacity: 0;
		overflow-y: hidden;
		transform: scale(0.5);
	}
	100% {
		opacity: 1;
		overflow-y: hidden;
		text-replace: scale(1);
	}
}

.modalExitBtn {
	all: unset;
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
}

.modalExitBtn svg path {
	stroke: rgba(255, 255, 255, 0.8);
	transition: 0.3s ease;
}

.modalExitBtn:hover svg path {
	stroke: #ff2e63;
}

@media only screen and (max-width: 960px) {
	.modalHeadImg {
		width: 12rem;
		height: 12rem;
	}
	.modalHeadContent h2 {
		font-size: 3rem;
	}
	.modalHeadContent p {
		font-size: 1.6rem;
	}
	.modalMainContent h4 {
		font-size: 3rem;
	}
	.modalMainContent p {
		font-size: 1.6rem;
	}
}

@media only screen and (max-width: 480px) {
	.modalContent {
		margin: 1rem;
	}
	.modalMainContent p {
		padding: 1rem 2rem 0;
	}
}
@media only screen and (max-width: 380px) {
	.modalContent {
		margin: 1rem;
	}
	.modalMainContent p {
		font-size: 1.4rem;
		padding: 1rem 1rem 0;
	}
	.modalHead img {
		width: 10rem;
		height: 10rem;
	}
	.modalHead h2 {
		font-size: 2.4rem;
	}
	.modalHead p {
		font-size: 1.4rem;
	}
}
