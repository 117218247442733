.header {
	padding: 4.8rem 6.4rem 0px;
	min-height: 100vh;
	height: 100vh;
	margin: 0 auto;
}

@media only screen and (max-width: 630px) {
	.header {
		padding: 4.8rem 3.2rem 0px;
	}
}
@media only screen and (max-width: 600px) {
	.header {
		padding: 2.8rem 3.2rem 0px;
	}
}
