.headers {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 6rem;
}
.headers--head {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 3rem;
}

.headers--h2 {
	font-family: "Montserrat", sans-serif;
	font-size: 3.2rem;
	opacity: 0;
	transform: translateY(50px);
}

.headers--underscore {
	width: 40px;
	height: 6px;
	border-radius: 100px;
	margin-top: 1.5rem;
	opacity: 0;
}
.blueU {
	background-color: #08d9d6;
}
.redU {
	background-color: #ff2e63;
}

.headers--p {
	font-family: "Open Sans", sans-serif;
	font-size: 1.6rem;
	color: rgba(255, 255, 255, 0.95);
	text-align: center;
	max-width: 75rem;
	opacity: 0;
}
