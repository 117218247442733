.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(37, 42, 52, 0.6);
	z-index: 100;
	animation: fadeins 0.3s ease;
	overflow-y: hidden;
}
@keyframes fadeins {
	0% {
		background-color: transparent;
	}
	100% {
		background-color: rgba(37, 42, 52, 0.6);
	}
}
