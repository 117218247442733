.projectsMain {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	gap: 2rem;
	justify-content: center;
	margin: 4rem auto;
	max-width: 180rem;
}
@media only screen and (min-width: 1980px) {
	.projectsMain {
		grid-template-columns: repeat(3, minmax(400px, 1fr));
	}
}
@media only screen and (max-width: 500px) {
	.projectsMain {
		grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	}
}
@media only screen and (max-width: 400px) {
	.projectsMain {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		/* margin: 4rem auto; */
		gap: 1rem;
		margin: 0 -2rem;
		margin-bottom: 2rem;
	}
}
@media only screen and (max-width: 325px) {
	.projectsMain {
		grid-template-columns: 1fr;
		margin: 0 -3rem;
		margin-bottom: 2rem;
	}
}
